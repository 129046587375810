import swal from 'sweetalert';
import {
  addContentDiv,
  addToast,
  checkValueAgainstList,
  createAndAddInfoTableWide,
  displayErrorPopup,
  displayInfoPopup,
  displaySuccessPopup,
  showErrorFromApiOperation,
} from './main';
import { formatChildRowListAccountsHeader, formatChildRowListAccountsLeftSide } from './accounts_table';
import { addButtonSpinner, addSpinner, removeSpinners } from './sidebar';
import { apiAxios, baseApiAxios, loadSettings } from './api';
import { COGNITO } from './auth';
import { checkResponsibility } from './dashboard';
import { setSearchParamsUrl } from './search_params';
import AccountTabs from '../../jsx/components/forms/AccountTabs';
import EditAccountsForm from '../../jsx/components/forms/EditAccountsForm';

export const accountIdPattern = /^\d{12}$/;

export function showAccountTab(n) {
  const x = document.getElementsByClassName('tab');
  for (let tab of x) {
    tab.style.display = 'none';
  }
  x[n].style.display = 'block';
}

export function accountResetSpinners(submitButton = '#submitButton') {
  $(submitButton).removeClass('loading-animation');
  $(submitButton).attr('disabled', false);
  removeSpinners();
}

export function accountResetForm(form, submitButton = '#submitButton') {
  $(form)[0].reset();
  if ($(submitButton)[0]) {
    $(submitButton)[0].value = 'Continue';
    $(submitButton)[0].setAttribute('class', 'btn btn-success');
  }
  $(submitButton).removeClass('loading-animation');
  $(submitButton).attr('disabled', false);
  $('#account-selector').removeClass('has-error');
  $('.selectpicker').each(() => {
    const selectpicker = $(this);
    const isDisabled = selectpicker.hasClass('disabled');
    selectpicker.selectpicker('refresh');
    selectpicker.not('.no-deselect').selectpicker('deselectAll');
    if (isDisabled) selectpicker.attr('disabled', true);
  });
  $('#form-error').hide();
  $('#form-deletion-warning').hide();
  showAccountTab(0);
}

export async function checkAccountResources() {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];
  const [vpcs, hosted_zones, fourwheels] = await Promise.all([
    baseApiAxios.get('/accounts/' + account_id + '/vpcs', null, { only_active: true, fields: 'vpc_id' }),
    baseApiAxios.get('/accounts/' + account_id + '/dns', null, { only_active: true, fields: 'fqdn' }),
    baseApiAxios.get('/accounts/' + account_id + '/fourwheels', null, {
      exclude_deleted: true,
      fields: 'project_name',
    }),
  ]).catch(showErrorFromApiOperation('Error retrieving remaining resources'));

  return vpcs.items
    .map(function (item) {
      return {
        Type: 'VPC',
        ID: item.vpc_id,
      };
    })
    .concat(
      hosted_zones.items.map(function (item) {
        return {
          Type: 'Hosted Zone',
          ID: item.fqdn,
        };
      }),
      fourwheels.items.map(function (item) {
        return {
          Type: '4wheels',
          ID: item.project_name,
        };
      }),
    );
}

export function closeAccount(callbackFct) {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];

  swal({
    title: 'Are you sure?',
    text:
      'Are you sure you want to close AWS account ' +
      account_id +
      '? Once the account is closed, BMW will lose access to all resources and data!',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Close Account',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(response => {
    if (response) {
      const payload = {
        action: 'account-close',
        description: 'Close AWS Account ' + account_id,
        account_id: account_id,
      };

      baseApiAxios
        .createOrder(payload)
        .then(result => {
          if (callbackFct) callbackFct();
          displaySuccessPopup(result.message);
        })
        .catch(displayErrorPopup)
        .finally(() => {
          accountResetForm('#close-account-form');
          removeSpinners();
        });
    } else {
      accountResetSpinners();
    }
  });
}

export function requestIcpException(callbackFct) {
  const account_id = $('#aws-account-id')[0].value.split(';')[0];

  swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to request a ICP exception for the account ' + account_id + '?',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Request ICP Exception',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(response => {
    if (response) {
      const payload = {
        action: 'account-icp-exception',
        description: 'Request ICP exception for the account ' + account_id,
        account_id: account_id,
      };

      baseApiAxios
        .createOrder(payload)
        .then(result => {
          if (callbackFct) callbackFct();
          displaySuccessPopup(result.message);
        })
        .catch(displayErrorPopup)
        .finally(() => {
          accountResetForm('#icp-exception-account-form');
          removeSpinners();
        });
    } else {
      accountResetSpinners();
    }
  });
}

export async function accountGetDetails(form) {
  const account_id = document.getElementById('aws-account-id').value;
  try {
    const account_details = await baseApiAxios.getAccount(account_id);
    accountDisplayDetails(account_details, form);
  } catch (err) {
    showErrorFromApiOperation('Failed to fetch account details')(err);
  }
}

function accountDisplayDetails(account_data, form) {
  const content = document.getElementById('account-details');
  content.setAttribute('class', 'detailsContainer');
  content.innerHTML = '';

  const infoContainer = document.createElement('div');
  content.appendChild(infoContainer);
  formatChildRowListAccountsHeader(account_data, infoContainer);

  const detailContainer = document.createElement('div');
  content.appendChild(detailContainer);
  formatChildRowListAccountsLeftSide(account_data, detailContainer);

  const permissions = localStorage.permissions || false;
  const account_areas = localStorage.account_areas || false;
  const responsible = checkResponsibility(account_data, COGNITO.user.email);
  const area_admin = checkValueAgainstList(account_data['account_area'], account_areas);

  if (
    checkValueAgainstList('manage_organizations', permissions) ||
    checkValueAgainstList('manage_accounts', permissions) ||
    responsible ||
    area_admin ||
    form === 'icp-exception-account-form'
  ) {
    $('#submitButton').attr('disabled', false);
  } else {
    $('#submitButton').attr('disabled', true);
  }

  if (account_data.marked_for_deletion) {
    $('#submitButton').attr('disabled', true);
  }
  removeSpinners();
}

export function accountDisplayRemainingResources(response) {
  const content = document.getElementById('account-details');
  content.setAttribute('class', 'detailsContainer');
  content.innerHTML = '';

  const infoContainer = document.createElement('div');
  infoContainer.setAttribute('class', 'detailsContent');
  content.appendChild(infoContainer);

  const header = document.createElement('h4');
  header.innerText = 'Remaining Resources';
  infoContainer.appendChild(header);

  const headerText = document.createElement('p');
  headerText.innerText =
    'The selected account account can not be deleted. In order to close the account please delete the following remaining resources:';
  infoContainer.appendChild(headerText);

  const resourcesContainer = addContentDiv(content, 'detailsContent');
  const resourcesDetails = addContentDiv(resourcesContainer, 'div');

  const resourcesFields = ['Type', 'ID'];
  createAndAddInfoTableWide(response, resourcesFields, resourcesDetails, true);
}

export function updateAccountSummary(data) {
  const accounts_active = document.getElementById('account-active-value');
  const accounts_advanced = document.getElementById('account-advanced-value');
  const accounts_default = document.getElementById('account-default-value');
  const accounts_other = document.getElementById('account-other-value');
  const container_other = document.getElementById('account-other-container');

  const number_accounts = { advanced: 0, default: 0, other: 0 };
  data.forEach(function (item) {
    if (Object.prototype.hasOwnProperty.call(number_accounts, item.account_type)) {
      number_accounts[item.account_type] += 1;
    } else {
      number_accounts.other += 1;
    }
  });

  accounts_active.innerText = data.length;
  accounts_advanced.innerText = number_accounts.advanced;
  accounts_default.innerText = number_accounts.default;
  accounts_other.innerText = number_accounts.other;
  if (number_accounts.advanced + number_accounts.default !== data.length) {
    container_other.classList.remove('d-none');
  } else {
    container_other.classList.add('d-none');
  }
}

function parseText(encodedStr) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(encodedStr, 'text/html');
  return dom.body.textContent;
}

// update the whole page, e.g. after some database change was made
function updateAccountPage(accountId, d) {
  removeSpinners();
  switch (d.status) {
    case 200:
    case 201:
      hideAccountEditForm();
      if (window.location.hash === '#accountdetails') {
        window.location.search = window.sessionStorage.lastSearch;
        loadAccountData('', '', '', accountId);
        return;
      }
      break;
    case 400:
    case 401:
    case 404:
      $('#form-error')
        .html('<strong>Error: </strong>' + d.data.message)
        .show();
      break;
    case 412:
      $('#form-error').html(
        '<strong>Warning: </strong>' +
          d.data.message +
          '. you have entered an unsupported value or you are not allowed to update the following fields: ' +
          d.data.warning,
      );
      $('#form-error').show();
      break;
  }
}

export function loadAccountData(_eventTypeId, _eventCategory, _option, account_id) {
  hideAccountEditForm();
  if (!account_id) return;
  addSpinner();

  // Only add content if both API calls are successful, catch any errors from those API calls and remove the spinners
  // at the end.
  Promise.all([baseApiAxios.getAccount(account_id), baseApiAxios.getAccountPermissions(account_id)])
    .then(response => {
      const account_details = { ...response[0], ...response[1].permanent_roles };
      const temp_access_roles = response[1].temporary_roles || [];

      $('#account-details-main').html(
        <AccountTabs data={account_details} tempAccessRoles={temp_access_roles} isAccountDetailsPage={true} />,
      );
    })
    .catch(err => {
      showErrorFromApiOperation('Error fetching account information')(err);
    })
    .finally(() => {
      removeSpinners();
    });
}

// edit accounts

const editFields = [
  'region_category',
  'master_account',
  'account_id',
  'account_root_email',
  'creation_date',
  'fpc_status',
  'account_friendly_name',
  'description',
  'account_area',
  'account_type',
  'account_stage',
  'primary_responsible',
  'sec_responsible',
  'it_responsible',
  'app_id',
  'appd_id',
  'cost_center',
  'psp',
];

let editAccountInitialData = {};
let editAccountSettings = {};
let privateVpcFound;
let publicVpcFound;

// hide the edit account information form
export function hideAccountEditForm() {
  $('#edit-account-form').hide();
  $('.edit-account-show').hide();
  $('.edit-account-hide').show();
  const form = document.getElementById('create-or-edit-form');
  form?.reset();
  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
  remove_form_warnings();
}

export function preEditAccount(row) {
  let accountData;
  if (typeof row === 'number') {
    const dt = $('#table-accounts').DataTable({ retrieve: true });
    accountData = dt.row(row).data();
  } else if (typeof row === 'string') {
    accountData = JSON.parse(row);
  } else {
    accountData = row;
  }

  // Add the edit form only after someone clicked the edit button and not before
  $('#edit-accounts-form-container').html(
    <EditAccountsForm
      regionCategoryFct={updateRegionCategory}
      masterAccountIdFct={updateMasterAccountId}
      fpcStatusFct={updateFpcStatus}
      accountTypesFct={() => updateAccountTypes({}, accountData, true)}
      accountStagesFct={() => updateAccountStages(accountData)}
      hideFormFct={hideAccountEditForm}
      accountId={accountData.account_id}
      accountData={accountData}
    />,
  );
  $('#edit-account-form').show();
  $('.edit-account-show').show();
  $('.edit-account-hide').hide();

  editAccountInitialData = accountData;
  updateFpcStatusDropdown(accountData);
  addSpinner();
  addButtonSpinner();

  apiAxios
    .get(`/accounts/${accountData['account_id']}/vpcs?only_active=true&fields=vpc_id,network_type`)
    .then(apiResponse => {
      if (apiResponse)
        privateVpcFound = apiResponse.data.items.some(item => item && item['network_type'] === 'private');
      publicVpcFound = apiResponse.data.items.some(item => item && item['network_type'] === 'public');
    });

  loadSettings(['update_account_information']).then(settings => {
    editAccountSettings = settings;
    editAccount(settings, accountData);
  });
}

// fill the edit account form with current account information
function editAccount(settings, account_data) {
  const permissions = localStorage.permissions;
  const account_areas = localStorage.account_areas ? localStorage.account_areas : false;
  const manage_organizations = checkValueAgainstList('manage_organizations', permissions);
  const manage_accounts = checkValueAgainstList('manage_accounts', permissions);
  const responsible = checkResponsibility(account_data, COGNITO.user.email);
  const area_admin = checkValueAgainstList(account_data['account_area'], account_areas);
  const { update_account_information: update_settings } = settings;

  editFields.forEach(function (item) {
    $('#' + item).attr('disabled', true);
    const field = document.querySelector('#' + item);
    if (field) {
      switch (item) {
        case 'description':
          field.value = parseText(account_data[item] || '');
          break;
        case 'legacy':
          field.value = account_data[item] || 'legacy';
          break;
        case 'cost_center':
        case 'psp':
        case 'app_id':
        case 'appd_id':
          if (
            account_data &&
            account_data['cost_data'] &&
            account_data['cost_data'][item] &&
            account_data['cost_data'][item] !== 'false' &&
            account_data['cost_data'][item] !== 'unknown'
          ) {
            field.value = account_data['cost_data'][item];
          } else {
            field.value = '';
          }
          break;
        default:
          field.value = account_data[item] || '';
          break;
      }
    }
  });

  updateAccountAreas(settings, account_data);

  $('#card-heading-text')[0].innerText = 'Update AWS Account Record in DynamoDB';

  let updateEnabled = true;

  const enabledFields = update_settings.attributes?.enabled || [];

  if (manage_organizations || manage_accounts || responsible || area_admin) {
    enabledFields.forEach(function (item) {
      const element = $('#' + item);
      element.attr('disabled', false);

      // Text fields with edit buttons next to it have to be handled differently
      if (element.hasClass('button-control')) {
        const elementEdit = $('#' + item + '_edit');
        elementEdit.attr('disabled', false);
      }
    });
  } else {
    updateEnabled = false;
  }

  const updateAccountBtn = document.querySelector('button.update-account');
  if (updateAccountBtn) updateAccountBtn.disabled = !updateEnabled;

  updateFpcStatus();
  removeSpinners();
  setSearchParamsUrl({ account_id: account_data.account_id });
}

function updateFpcStatusDropdown(account_data) {
  const sel = document.getElementById('fpc_status');

  if (!sel) return;

  if (sel.options) {
    for (let i = sel.options.length - 1; i >= 0; i--) {
      sel.remove(i);
    }
  }

  const fpc_stats = {
    new: 'New',
    enabled: 'Enabled',
    external: 'External',
  };

  const current_fpc_status = account_data['fpc_status'];
  if (!fpc_stats[current_fpc_status]) {
    fpc_stats[current_fpc_status] = current_fpc_status;
  }

  for (let stat in fpc_stats) {
    const opt = document.createElement('option');
    opt.value = stat;
    opt.innerText = fpc_stats[stat];

    if (account_data) {
      if (stat === account_data.fpc_status) {
        opt.selected = true;
      }
    }
    sel.appendChild(opt);
  }
}

export function updateFpcStatus() {
  const fpc_status = $('#fpc_status')[0].value;

  switch (fpc_status) {
    case 'deleted':
      ['fpc_status'].forEach(function (item) {
        $('#' + item).attr('disabled', true);
      });
      break;
    case 'halt':
      editFields.forEach(function (item) {
        if (['account_root_email', 'fpc_status'].indexOf(item) === -1) $('#' + item).attr('disabled', true);
      });
      break;
    default:
  }
}

export function updateRegionCategory() {
  const region_category = $('#region_category')[0].value;
  if (region_category === 'China') {
    $('#master_account').attr('disabled', true);
    $('#account_root_email').attr('disabled', true);
    $('#master_account')[0].value = '';
  } else {
    $('#master_account').attr('disabled', false);
    $('#account_root_email').attr('disabled', false);
  }

  updateAccountTypes();
}

export function updateMasterAccountId() {
  updateAccountAreas(editAccountSettings);
}

function getAllowedFieldValues(conditionsArray, accountData, masterAccount, accountArea) {
  const userPermissions = localStorage.permissions?.split(',');

  let allowedValues = [];

  conditionsArray.forEach(conditionObj => {
    const allConditions = Object.assign(conditionObj.conditions?.account || {}, conditionObj.conditions?.network || {});
    const conditionKeys = Object.keys(allConditions);
    const conditionsMet =
      conditionKeys.length > 0
        ? conditionKeys.every(key => {
            switch (key) {
              case 'master_account':
                return allConditions[key].includes(masterAccount || accountData[key]);
              case 'account_area':
                return allConditions[key].includes(accountArea || accountData[key]);
              case 'network_type':
                if (allConditions[key].includes('public') && publicVpcFound) {
                  return false;
                } else if (allConditions[key].includes('private') && privateVpcFound) {
                  return false;
                } else {
                  return true;
                }
              default:
                return allConditions[key].includes(accountData[key]);
            }
          })
        : true;
    const permissionsAvailable =
      conditionObj.permissions?.length > 0
        ? conditionObj.permissions.every(permission => userPermissions.includes(permission))
        : true;
    if (conditionsMet && permissionsAvailable) allowedValues = allowedValues.concat(conditionObj.values);
  });

  return allowedValues;
}

function updateAccountAreas(settings, account_data) {
  account_data = account_data || {};

  const masterAccount = $('#master_account').val();
  const accountArea = account_data.account_area;
  const { update_account_information: updateAccountSettings } = settings;
  const conditionsArray = updateAccountSettings.attributes?.conditional?.account_area || [];

  let accountAreaValues = getAllowedFieldValues(conditionsArray, account_data, masterAccount);

  if (accountArea && accountAreaValues.indexOf(accountArea) === -1) {
    accountAreaValues.push(accountArea);
  }
  if (accountAreaValues.length === 0) {
    accountAreaValues.push('unknown');
  }

  $('select#account_area')
    .empty()
    .append(
      accountAreaValues.map(key =>
        $('<option>')
          .val(key)
          .text(key)
          .attr('selected', key === accountArea || (!accountArea && key === 'fpc')),
      ),
    );

  updateFpcStatusDropdown(account_data);
  updateAccountTypes(settings, account_data);
}

export function updateAccountTypes(settings, account_data, skip_load_settings) {
  if (settings || skip_load_settings) {
    updateAccountTypesWithSettings(settings, account_data);
  } else {
    loadSettings('update_account_information').then(settingsFromAPI => {
      updateAccountTypesWithSettings(settingsFromAPI, account_data);
    });
  }
}

function updateAccountTypesWithSettings(settings, accountData) {
  removeSpinners();

  const masterAccount = $('#master_account').val();
  const accountArea = $('#account_area').val();

  const { update_account_information: updateAccountSettings } = settings;
  const conditionsArray = updateAccountSettings.attributes?.conditional?.account_type || [];

  let accountTypeValues = getAllowedFieldValues(conditionsArray, accountData, masterAccount, accountArea);

  const accountType = accountData.account_type || 'advanced';
  if (accountTypeValues.indexOf(accountType) === -1) {
    accountTypeValues.push(accountType);
  }
  if (accountType === 'default' && privateVpcFound) {
    const index = accountTypeValues.indexOf('advanced');
    if (index !== -1) accountTypeValues.splice(index, 1);
  }
  if (accountTypeValues.length === 0) {
    accountTypeValues.push('unknown');
  }

  updateAccountTypesDropdown(accountTypeValues, accountType);
  updateAccountStages(accountData);
}

function updateAccountTypesDropdown(types, account_type) {
  const sel = document.getElementById('account_type');
  for (let i = sel.options.length - 1; i >= 0; i--) {
    sel.remove(i);
  }

  types.forEach(type => {
    const opt = document.createElement('option');
    opt.value = type;
    opt.innerText = type;

    if (type === account_type) {
      opt.selected = true;
    }

    sel.appendChild(opt);
  });
}

export function updateAccountStages(account_data) {
  const appid = $('#app_id')[0].value;
  const appid_pattern = /^APP-\d{4,6}$/;
  const appid_result = appid_pattern.test(appid);

  let { account_stage: accountStage } = account_data || {};
  if (!accountStage) {
    accountStage = $('#account_stage')[0].value;
  }

  let stages = {
    basic: 'Basic',
  };

  if (appid_result) {
    Object.assign(stages, { int: 'Int', test: 'Test', prod: 'Prod' });
  }

  if (account_data) {
    if (!(account_data.account_stage in stages)) {
      stages[account_data.account_stage] = account_data.account_stage;
    }
  }

  updateAccountStagesDropdown(stages, accountStage);
}

function updateAccountStagesDropdown(stages, account_stage) {
  const sel = document.getElementById('account_stage');

  for (let i = sel.options.length - 1; i >= 0; i--) {
    sel.remove(i);
  }

  for (let stage in stages) {
    const opt = document.createElement('option');
    opt.value = stage;
    opt.innerText = stages[stage];

    if (account_stage) {
      if (stage === account_stage || stage === 'int' || stage === 'basic') {
        opt.selected = true;
      }
    } else {
      if (stage === 'int') {
        opt.selected = true;
        account_stage = 'int';
      } else if (stage === 'basic') {
        opt.selected = true;
        account_stage = 'basic';
      }
    }
    sel.appendChild(opt);
  }
}

function remove_form_warnings() {
  $('#form-error').hide();
  $('#form-success').hide();

  editFields.forEach(function (item) {
    $('#form_group_' + item).removeClass('has-success');
    $('#form_group_' + item).removeClass('has-error');
  });
}

// validate the edit account form
export function validateAccountForm() {
  $(() => addSpinner());
  $(() => addButtonSpinner());
  const email_pattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const friendlyNamePattern = /^[^<>=]*$/;

  remove_form_warnings();

  if ($('#region_category')[0].value !== 'China') {
    // MASTER ACCOUNT ID
    if (!accountIdPattern.test($('#master_account')[0].value)) {
      $('#form_group_master_account').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Master Account must be a 12 digit long value!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_master_account').addClass('has-success');
    }
  }

  // ACCOUNT ID
  if (!accountIdPattern.test($('#account_id')[0].value)) {
    $('#form_group_account_id').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> AWS Account ID must be a 12 digit long value!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_id').addClass('has-success');
  }

  // CHECK ROOT AND MASTER ACCOUNT STUFF ONLY FOR ACCOUNTS OUTSIDE OF CHINA
  if ($('#region_category')[0].value !== 'China') {
    // ACCOUNT ROOT EMAIL
    if (!$('#account_root_email')[0].value) {
      $('#form_group_account_root_email').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Root Email can not be empty!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      if (!email_pattern.test($('#account_root_email')[0].value.toLowerCase())) {
        $('#form_group_account_root_email').addClass('has-error');
        $('#form-error').html('<strong>Warning: </strong> Root Email must be an actual email address!');
        $('#form-error').show();
        window.scrollTo(0, 0);
        return;
      } else {
        $('#form_group_account_root_email').addClass('has-success');
      }
    }
  }

  // CREATION DATE
  if (!$('#creation_date')[0].value) {
    $('#form_group_creation_date').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Creation Date can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_creation_date').addClass('has-success');
  }

  // FRIENDLY NAME
  if (!$('#account_friendly_name')[0].value) {
    $('#form_group_account_friendly_name').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Friendly Name can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else if (!friendlyNamePattern.test($('#account_friendly_name')[0].value)) {
    $('#form_group_account_friendly_name').addClass('has-error');
    $('#form-error').html(
      '<strong>Warning: </strong> Friendly Name contains invalid characters ' +
        '(<code>&lt;</code>, <code>&gt;</code> or <code>=</code>)!',
    );
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_friendly_name').addClass('has-success');
  }

  // ACCOUNT AREA
  if (!$('#account_area')[0].value) {
    $('#form_group_account_area').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Account Area can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_area').addClass('has-success');
  }

  // ACCOUNT TYPE
  if (!$('#account_type')[0].value) {
    $('#form_group_account_type').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Account Type can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_type').addClass('has-success');
  }

  // ACCOUNT STAGE
  if (!$('#account_stage')[0].value) {
    $('#form_group_account_stage').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Account Type can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    $('#form_group_account_stage').addClass('has-success');
  }

  // PRIMARY RESPONSIBLE
  if (!$('#primary_responsible')[0].value) {
    $('#form_group_primary_responsible').addClass('has-error');
    $('#form-error').html('<strong>Warning: </strong> Primary Responsible can not be empty!');
    $('#form-error').show();
    window.scrollTo(0, 0);
    return;
  } else {
    if (!email_pattern.test($('#primary_responsible')[0].value.toLowerCase())) {
      $('#form_group_primary_responsible').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Primary Responsible must be a valid email address!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_primary_responsible').addClass('has-success');
    }
  }

  // SECONDARY RESPONSIBLE
  if ($('#sec_responsible')[0].value) {
    const sec_email_result = email_pattern.test($('#sec_responsible')[0].value.toLowerCase());

    if (!sec_email_result) {
      $('#form_group_sec_responsible').addClass('has-error');
      $('#form-error').html('<strong>Warning: </strong> Secondary Responsible must be a valid email address!');
      $('#form-error').show();
      window.scrollTo(0, 0);
      return;
    } else {
      $('#form_group_sec_responsible').addClass('has-success');
    }
  } else {
    $('#form_group_sec_responsible').addClass('has-success');
  }

  // update dynamodb when all values are verified successfully
  updateAccountInDB();
}

/**
 * API gateway call to insert/update the account information in dynamodb
 */
function updateAccountInDB() {
  const accountAreaChanged = editAccountInitialData['account_area'] !== $('#account_area').val();
  const accountTypeChanged = editAccountInitialData['account_type'] !== $('#account_type').val();
  const requiresOrder = accountAreaChanged || accountTypeChanged;

  const { update_account_information: update_settings } = editAccountSettings;
  const enabledFields = update_settings?.attributes?.enabled || [
    'account_friendly_name',
    'description',
    'primary_responsible',
    'sec_responsible',
  ];

  const updatePayloadFields = enabledFields.filter(
    fieldname => ['account_area', 'account_type'].indexOf(fieldname) === -1,
  );

  const displaySwal = errorMessage => {
    const orderContainer = document.createElement('div');
    orderContainer.style = 'margin: 2rem 2rem 0;';

    if (requiresOrder) {
      const orderReason = document.createElement('textarea');
      orderReason.setAttribute('rows', '4');
      orderReason.className = 'swal-textarea';

      const textareaInputListener = evt => {
        if (evt.target.value && evt.target.value.length > 10) {
          swal.setActionValue({ confirm: { reason: evt.target.value } });
        }
      };

      const orderRequiredExplanation =
        '<strong>Changing the account area or account type requires the creation of an order.<br/>For a timely approval, you are required to provide a reason for the change:</strong>';
      if (errorMessage) {
        orderContainer.innerHTML = `<div class="alert alert-danger swal-error-container" role="alert">${errorMessage}</div>${orderRequiredExplanation}`;
        orderReason.addEventListener('input', evt => {
          textareaInputListener(evt);
          const errorDiv = document.querySelector('.swal-error-container');
          if (errorDiv) errorDiv.style.display = 'none';
        });
      } else {
        orderContainer.innerHTML = orderRequiredExplanation;
        orderReason.addEventListener('input', textareaInputListener);
      }
      orderContainer.appendChild(orderReason);
    } else {
      orderContainer.display = 'none';
    }

    let accountInformation = {};
    updatePayloadFields.forEach(field => {
      const element = $('#' + field);
      if (!element.attr('disabled') && element.val()) {
        accountInformation[field] = element.val();
      }
    });

    let accountValuesChanged;
    Object.entries(accountInformation).forEach(([key, value]) => {
      if (editAccountInitialData[key] !== value) accountValuesChanged = true;
    });

    if (accountValuesChanged || requiresOrder) {
      swal({
        closeOnClickOutside: false,
        title: 'Are you sure?',
        content: {
          element: orderContainer,
        },
        text: `Are you sure you want to save this info into the database?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
          },
          confirm: {
            text: 'Update',
            value: {},
            visible: true,
          },
        },
      }).then(function (response) {
        if (response) {
          if (requiresOrder) {
            if (!response.reason) {
              displaySwal(`'Reason for the Order' is a required field`);
              return;
            }
            const orderPayload = {
              action: 'update-account-area',
              reason: response.reason,
              description: `Update Account ${editAccountInitialData['account_id']} - ${[
                accountAreaChanged ? 'Account Area' : null,
                accountTypeChanged ? 'Account Type' : null,
              ]
                .filter(elem => elem !== null)
                .join(' and ')}`,
              account_id: editAccountInitialData['account_id'],
              account_type: accountTypeChanged ? $('#account_type')[0].value : undefined,
              account_area: accountAreaChanged ? $('#account_area')[0].value : undefined,
            };
            apiAxios
              .post('/orders', {
                action: 'create-order',
                payload: orderPayload,
              })
              .then(orderResponse => {
                addToast('Create Order', orderResponse?.data?.message, 6000);
                if (accountValuesChanged) {
                  sendAccountUpdateRequest(editAccountInitialData['account_id'], accountInformation);
                } else {
                  hideAccountEditForm();
                }
              })
              .catch(showErrorFromApiOperation('Error creating account update Order'))
              .finally(() => removeSpinners());
          } else {
            sendAccountUpdateRequest(editAccountInitialData['account_id'], accountInformation);
          }
        } else {
          removeSpinners();
        }
      });
    } else {
      displayInfoPopup(`The account dataset will not change.`, 'No changes found').then(() => removeSpinners());
    }
  };

  displaySwal();
}

function sendAccountUpdateRequest(accountId, accountInformation) {
  apiAxios
    .put('/accounts/' + accountId, accountInformation)
    .then(responsePut => {
      updateAccountPage(accountId, responsePut);
    })
    .catch(showErrorFromApiOperation('Error updating Account'))
    .finally(() => removeSpinners());
}
